<template>
  <div
    v-style:mr="{ md: '10rem' }"
    class="fixed bottom-0 right-0 w-80 shadow-sm"
    @keydown.esc="$emit('close')"
  >
    <base-button
      v-if="!expanded"
      class="absolute inset-x-0 bottom-0 flex items-center gap-4 bg-inherit px-4 py-2 between"
      @click="expanded = true"
    >
      <slot name="title" />
      <span class="sr-only">
        ({{ $t.popupExpand }})
      </span>
      <vf-icon name="plus" size="md" />
    </base-button>
    <transition name="slide-up">
      <div v-if="expanded" class="bg-white">
        <div class="bg-$bg p-4 c-$c">
          <div class="mb-2 flex gap-4">
            <slot name="titleExpanded" />
            <base-button :aria-label="$t.popupMinimize" class="ml-a" @click="expanded = false">
              <vf-icon name="minus" size="md" />
            </base-button>
            <base-button :aria-label="$t.popupClose" @click="$emit('close')">
              <vf-icon name="close" size="md" />
            </base-button>
          </div>
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
defineEmits<{
  close: []
}>()

const expanded = defineModel<boolean>()
</script>
