<template>
  <base-radio
    v-bind="{ value, name, disabled }"
    v-model="modelValue"
    data-test-id="vf-size-picker"
  >
    <span :class="boxClasses">
      <span
        class="w-full select-none bg-inherit"
        :class="brandClasses.label"
        data-test-id="vf-size-picker-text"
      >
        <!-- @slot Slot for label -->
        <slot />
      </span>
    </span>
  </base-radio>
</template>

<script lang="ts" setup>
import type { CSSClass } from '#types/common'

const props = defineProps<{
  /**
   * Equal to native 'value' attribute of input:radio
   */
  value?: string
  /**
   * Equal to native 'name' attribute of input:radio
   */
  name?: string
  /**
   * Defines the size of the size picker
   */
  size?: 'sm' | 'md'
  /**
   * Sets the disabled state of the size picker
   */
  disabled?: boolean
  /**
   * Unavailable means the variant is out of stock, it will appear disabled but remain selectable
   */
  unavailable?: boolean
  /**
   * Defines the type of the size picker (width, length, size)
   */
  type?: string
  /**
   * Classes to be applied to content span container
   */
  classContent?: CSSClass
}>()

const modelValue = defineModel<string>()

const { brandClasses, productAttributeSelectorSize } = useAppConfig().components.vf.sizePicker

const boxClasses = computed(() => {
  const { disabled, type, unavailable, value } = props
  const size = props.size || productAttributeSelectorSize[type!] || 'md'
  const selected = modelValue.value === value

  return [
    'flex center b text-center transition peer-focus-visible:outline-auto',
    brandClasses.box,
    props.classContent,
    type === 'width' && brandClasses.rectangle,
    size === 'sm' ? brandClasses.sizeSm : brandClasses.sizeMd,
    (!selected && !unavailable && !disabled && [brandClasses.unselected, 'cursor-pointer']),
    (unavailable && !selected && [brandClasses.unavailable, 'cursor-pointer']),
    (!unavailable && selected && brandClasses.selected),
    (unavailable && selected && brandClasses.unavailableSelected),
    (disabled && brandClasses.disabled)
  ].filter(Boolean)
})
</script>
