<template>
  <base-collapse
    v-model="modelValue"
    :value
    :class-content="classContent"
    :class-toggle="classToggle"
    class="f-col b-t b-grey-70"
    :class="[{ 'children:px-4': !variant }]"
    data-test-id="vf-accordion"
  >
    <template #toggle="{ expanded }">
      <slot name="toggle" :expanded>
        <span
          class="flex items-center between"
          :class="[size ? 'py-1 ' : 'py-3']"
          data-test-id="vf-accordion-toggle"
        >
          <span
            class="truncate"
            :class="classTitle ?? [size === 'sm' ? 'subtitle-5' : 'title-3 !subtitle-7']"
          >
            {{ title }}
          </span>
          <vf-icon :name="icon || 'chevron'" :size="size ? iconSize.sm : 'lg'" :dir="expanded ? 'up' : 'down'" />
        </span>
      </slot>
    </template>
    <template #default>
      <div  data-test-id="vf-accordion-content">
        <slot />
      </div>
    </template>
  </base-collapse>
</template>

<script lang="ts" setup>
import type { IconName } from '#types/icon'
import type { CSSClass } from '#types/common'

defineProps<{
  /**
   * Text that will be rendered in top section if no toggle-slot passed
   */
  title?: string
  /**
   * Icon that will be rendered in top section if no toggle-slot passed
   */
  icon?: IconName
  /**
   * If 'sm' selected then titles are smaller and less vertical space is used.
   */
  size?: 'sm'
  /**
   * If 'aligned' selected then border and content
   * align to the title text and expand/collapse icon.
   */
  variant?: 'aligned'
  /**
   * Classes to be applied to content container.
   */
  classContent?: CSSClass
  /**
   * Classes to be applied to toggle button.
   */
  classToggle?: CSSClass
  /**
   * Optional title class to be applied to the accordion title
   */
  classTitle?: CSSClass
  /**
   * Allows for use within a group where only one accordion can be expanded at a time.
   * In this case, all accordions in the group should be bound to the same model value
   * and each individual accordion should have a unique `value`.
   */
  value?: string
}>()

const modelValue = defineModel<boolean | string>('open')
const { iconSize } = useAppConfig().components.vf.accordion
</script>
