<template>
  <div
    class="vf-dl77wy"
    :class="[
      {
        '[&>:first-child]:c-grey-30': $slots.select && disabled,
        'vf-clf739': $slots.select,
      },
      disabled ? 'bg-grey-90' : 'bg-white',
      disabled ? 'b-grey-50'
      : invalid ? (variant ? 'b-red-60' : 'b-red-30')
        : inputBrandClasses.borderColor,
    ]"
    data-test-id="vf-input"
  >
    <slot name="select" />
    <div class="grid cols-[1fr_auto] grow" data-test-id="vf-input-field">
      <label
        v-bind="attrs"
        class="vf-k1k1hk"
        :class="[
          inputBrandClasses.text,
          disabled ? 'vf-hi2b9u'
          : 'vf-r4tub8',
          {
            '!pr-10': invalid !== undefined || $slots.end,
            '!pr-18': invalid !== undefined && $slots.end,
          },
        ]"
      >
        <span
          v-if="$slots.default"
          class="col-start-1 col-end-2"
          :class="[
            labelBrandClasses.inputText,
            disabled ? 'c-grey-30'
            : invalid ? (variant ? 'c-red-60' : 'c-red-30')
              : 'c-grey-20',
          ]"
          data-test-id="vf-input-label"
        >
          <!-- @slot Slot is meant for `label` only -->
          <slot />
          <span v-if="required" aria-hidden="true"> *</span>
        </span>
        <slot name="input">
          <!-- @slot Slot is meant for passing `input` element for lower level configurations -->
          <base-input
            v-bind="$props"
            v-model="modelValue"
            @change="$emit('change', $event)"
          />
        </slot>
      </label>
      <div class="pointer-within col-start-2 row-start-1 flex self-center gap-1 pr-3">
        <!-- @slot Slot is meant for `icon` to the right of input. But may be used for any content -->
        <slot name="end" />
        <vf-icon
          v-show="invalid !== undefined"
          :name="invalid ? 'error' : 'success'"
          size="md"
          class="pointer-events-none"
          :class="invalid ? 'c-red-30' : 'c-green-30'"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
withDefaults(
  defineProps<{
    /**
     * Defines type of the input
     */
    type?: 'text' | 'email' | 'number' | 'search' | 'tel' | 'password' | 'date'
    /**
     * Defines placeholder when input is empty
     */
    placeholder?: string
    /**
     * Defines disabled state
     */
    disabled?: boolean
    /**
     * Defines validation state
     * There are 3 possible states
     * Indeterminate === undefined (no validation icons visible)
     * Valid === true
     * Invalid === false
     */
    invalid?: boolean
    /**
     * Defines variant
     */
    variant?: 'inverse'
    /**
     * Defines if the input is required for accessibility purposes
     */
    required?: boolean
    /**
     * Identifies the element (or elements) that describes the object.
     * Defined explicitly as a prop so it can be forwarded to the base input
     */
    ariaDescribedby?: string
    /**
     * Defines the minimum value that is acceptable
     * Works with types: number, date
     */
    min?: string
    /**
     * Defines the maximum value that is acceptable
     * Works with types: number, date
     */
    max?: string
  }>(),
  {
    type: 'text',
    /**
     * Needs to be explicitly set to `undefined` for indeterminate validation state
     */
    invalid: undefined
  }
)

defineEmits<{ change: [value: string] }>()

const {
  input: { brandClasses: inputBrandClasses },
  label: { brandClasses: labelBrandClasses }
} = useAppConfig().components.vf.form
const modelValue = defineModel<string | number>()

const { class: _, ...attrs } = useAttrs()
</script>
